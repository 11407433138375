.curved-corner-right,
.curved-corner-left {
  overflow: hidden;
  position: relative;
}

.curved-corner-right:before,
.curved-corner-left:before {
  content: '';
  display: block;
  width: 200%;
  height: 200%;
  position: absolute;
  border-radius: 50%;
  bottom: 0;
}

.curved-corner-right:before {
  left: 0;
  box-shadow: -25px 25px 0 0 white;
}

.curved-corner-left:before {
  right: 0;
  box-shadow: 25px 25px 0 0 white;
}

input[type=number].arrows-none::-webkit-inner-spin-button, 
input[type=number].arrows-none::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}